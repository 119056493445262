import {
  type Nullable,
} from '@web-solutions/core/constants/remote-config';
import type { InterButtonConfig } from '@web-solutions/core/payment/constants';

import {
  INITIAL_CONFIG as BASE_INITIAL_CONFIG,
  type RemoteConfig as BaseRemoteConfig,
  SummarySection as BaseSummarySection,
} from '@web-solutions/base-app/constants/remote-config';
import { CoreSummarySection } from '@web-solutions/core/summary/types';

import { type CardsUpsalesOffer, type DefaultUpsaleOffer, OfferProductId } from 'src/types/upsales';

import { type QuizRoute } from './routes';
import type { AfterPurchaseRoutes } from './screens';

enum LocalSummarySection {
  DISCOUNT_TIMER = 'discount_timer',
  DISCOUNT_TIMER_BUTTON = 'discount_timer_button',

  HEADER = 'header',
  HEADER_GOALS = 'header_goals',
  CLOTHES_HEADER_GOALS = 'clothes_header_goals',
  AGES_HEADER_GOALS = 'ages_header_goals',
  HEADER_IMAGE = 'header_image',
  ALT_HEADER_IMAGE = 'alt_header_image',
  PERSONAL_SUMMARY = 'personal_summary',
  WARDROBE = 'wardrobe',
  WARDROBE_IMPROVEMENT = 'wardrobe_improvement',
  WHAT_GET = 'what_get',
  WHAT_GET_V2 = 'what_get_v2',
  FORMULA = 'formula',
  STYLE_FORMULA = 'style_formula',
}

export const SummarySection = { ...LocalSummarySection, ...BaseSummarySection }
export type SummarySectionType = LocalSummarySection | BaseSummarySection;

export type AfterPurchaseFlow = AfterPurchaseRoutes;

export interface RemoteConfig
  extends Omit<
    BaseRemoteConfig<QuizRoute>,
    'landingStack'
    | 'landingButtons'
    | 'emailPageSections'
    | 'magicType'
  > {
  createAccountFlow: Array<'email'>,

  landingStack: Array<SummarySectionType | CoreSummarySection>,
  landingButtons: Array<InterButtonConfig<SummarySectionType>>,
  emailPageSections: Array<SummarySectionType>,
  afterPurchaseFlow: AfterPurchaseFlow,

  styleGuideUpsale: DefaultUpsaleOffer,
  wardrobeGuideUpsale: DefaultUpsaleOffer,

  pdfGuides: CardsUpsalesOffer,

  cameraInitTimeout: number,
  isFaceReadingSkipAvailable: boolean,
  isFaceReadingUploadPhotoAvailable: boolean,
  isFaceReadingBackAvailable: boolean,
  isFaceReadingApproveAvailable: boolean,
  redirectTerminateToManage: boolean,

  isAllowSetApplinkToClipboard?: boolean
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...BASE_INITIAL_CONFIG,
  createAccountFlow: ['email'],

  flow: [],
  afterPurchaseFlow: [],

  wardrobeGuideUpsale: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '$49.99',
      activePrice: '$19.99',
      discount: '60%',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE_AND_TITLE",
  },

  styleGuideUpsale: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '',
      subTitle: '',
      notActivePrice: '$49.99',
      activePrice: '$19.99',
      discount: '60%',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_SLIDER",
  },

  pdfGuides: {
    title: '',
    subtitle: '',
    submitButton: '',
    skipButton: '',
    activeOfferId: OfferProductId.NEW_OFFER_1,
    products: []
  },

  cameraInitTimeout: 4000,
  isFaceReadingSkipAvailable: true,
  isFaceReadingUploadPhotoAvailable: false,
  isFaceReadingBackAvailable: false,
  isFaceReadingApproveAvailable: false,
  redirectTerminateToManage: false,

  isAllowSetApplinkToClipboard: true,
};

export type FullConfig = Nullable<Partial<RemoteConfig>>;
