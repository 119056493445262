import { useEffect, useState, useCallback } from 'react';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { removeCreateAccountParam } from '../utils';

import { useBaseProcesses } from './use-base-processes';

export type InitialData = {
  email: string,
  password: string,
  confirm_password: string,
}

export const useCreateAccount = (email: string, linkAuthResult?: any) => {

  const {
    data,
    validation,
    handleChange,
  } = useBaseProcesses(email);

  const [erorPopup, setErorPopup] = useState({
    show: false,
    provider: '',
  });

  const [isOpenSuccessPopup, setIsOpenSuccessPopup] = useState(false);

  const updateUrlParams = useCallback(() => {
    createBrowserHistory()
      .replace(queryString.stringifyUrl({
        url: window.location.pathname + window.location.search,
        query: { create_account: null }
      }));
  }, []);

  useEffect(() => {
    Analytics.trackEvent('firebase_auth', EVENT_ACTION.OPEN);
    updateUrlParams();

    return () => {
      removeCreateAccountParam();
    }

  }, [updateUrlParams]);

  useEffect(() => {
    if (linkAuthResult instanceof Error) {
      // @ts-ignore
      const code = linkAuthResult?.code;
      Analytics.trackEvent('firebase_auth', 'apple_error', { error: linkAuthResult?.message, code, provider: 'apple' });
      setErorPopup({ show: true, provider: 'apple' });
    } else if (linkAuthResult) {
      if (linkAuthResult?.email) {
        Analytics.trackEvent('firebase_auth', 'apple_success', { provider: 'apple' });
        setIsOpenSuccessPopup(true);
      } else {
        Analytics.trackEvent('firebase_auth', 'apple_error', { code: 'no-email', provider: 'apple' });
        setErorPopup({ show: true, provider: 'apple' });
      }
    }
  }, [linkAuthResult]);

  return {
    erorPopup,
    data,
    validation,
    isOpenSuccessPopup,
    handleChange,
    setErorPopup,
    setIsOpenSuccessPopup
  }
}