import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AccountManagmentState } from './types'

const initialState: AccountManagmentState = {
  uid: '',

  linkAuthResult: null,
};

export const slice = createSlice({
  name: 'accountManagement',
  initialState,
  reducers: {
    setUid: (state, action: PayloadAction<string | undefined>) => {
      state.uid = action.payload;
    },
    setLinkAuthResult: (state, action: PayloadAction<string>) => {
      state.linkAuthResult = action.payload;
    }
  },
})

export const blacklist = ['linkAuthResult'];