import { ReactComponent as IconApple } from './apple.svg';

import classes from './style.module.scss';

interface Props {
  text: string;
  onAppleClick: () => void;
}

export const AppleIdButton: React.FC<Props> = ({ text, onAppleClick }) => (
  <button className={classes.apple_button} onClick={onAppleClick}>
    <IconApple className={classes.icon} />
    {text}
  </button>
);