import React from 'react';
import classNames from 'classnames';
import { Textfit } from 'react-textfit'

import Analytics from '@web-solutions/module-analytics';

import { t, TM } from '@web-solutions/module-localization';

import { ButtonPlacementType, ButtonConfig } from '@web-solutions/core/payment/constants';
import TrialsLeft, { TrialsLeftType } from '@web-solutions/core/payment/components/trials-left';
import { ProductDetails, } from '@web-solutions/core/store/billing/selectors';

import { Button, Portal } from 'core/ui-elements';

import { useFixedButton } from 'core/hooks/use-fixed-button';
import { getButtonTitleByConfig, updateButtonConfig } from 'core/utils/button-configurator';

import { useTimer } from '../../payment/hooks/use-timer';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

export interface FixedButtonProps {
  activeProduct: ProductDetails,
  buttonsConfigs: { [key in ButtonPlacementType]: ButtonConfig },
  onClick: (type: ButtonPlacementType, forceOpen?: boolean) => void,
  id?: string,
  onlyFixed?: boolean,
  onlyStatic?: boolean,
  onPayPalSubmit?: (data: any) => void,
};

export const FixedButton: React.FC<FixedButtonProps> = ({
  id,
  activeProduct,
  buttonsConfigs,
  onlyFixed,
  onlyStatic,
  onClick,
  //TODO: remove onPayPalSubmit callbacks
  onPayPalSubmit,
}) => {
  const plansButtonConfig = buttonsConfigs[ButtonPlacementType.PLANS] || {};
  const fixedButtonConfig = buttonsConfigs[ButtonPlacementType.FIXED] || {};

  const title = getButtonTitleByConfig(
    updateButtonConfig({
      config: plansButtonConfig,
      updatingFields: { title: activeProduct.buttonTitle }
    }),
    'core.get_my_plan',
    activeProduct
  );
  const fixedBtnTitle = getButtonTitleByConfig(fixedButtonConfig, 'core.get_my_plan', activeProduct);

  const { fixed, isVisibleProducts } = useFixedButton({
    productsListSelector: id,
    buttonsSelector: '.sub-static-btn',
    productSelector: '.product-item'
  });

  const timer = useTimer();

  const handleButtonClick = (e: React.SyntheticEvent) => {
    Analytics.trackEvent('products_button', 'click');
    e.preventDefault();
    onClick(ButtonPlacementType.PLANS);
  };

  const handleFixedButtonClick = (e: React.SyntheticEvent) => {
    Analytics.trackEvent('fixed_button', 'click');
    e.preventDefault();
    onClick(ButtonPlacementType.FIXED, isVisibleProducts.current);
  };

  return (
    <>
      {!onlyFixed && (
        <div className={classNames(classes.content, classes.buttonMargin)}>
          {activeProduct.trialsLeftBadge && <TrialsLeft type={TrialsLeftType.BADGE_BUTTON} />}
          <Button
            data-testid='summary-submit-btn'
            onClick={onlyFixed ? handleFixedButtonClick : handleButtonClick}
            className={classNames('sub-static-btn', classes.button, {
              [classes.invisible]: fixed && !onlyStatic,
            })}
            titleClassName={classes.buttonTitleStyle}
            title={
              <Textfit mode='multi' max={22} className={classes.signboardTitle}>
                {title}
              </Textfit>
            }
            flare={!!plansButtonConfig?.enabledFlareAnimation}
          />
        </div>
      )}
      {!onlyStatic && (
        <Portal>
          <div
            className={classNames(classes.fixed, {
              [classes.hidden]: !fixed,
            })}
          >
            {timer?.shown && !onlyFixed && (
              <div
                className={classes.discount}
                onClick={handleFixedButtonClick}
              >
                <div className={classes.timer}>
                  <b>{timer?.minutes}</b><span className={classes.colon}>:</span><b>{timer?.seconds}</b>
                </div>
                {!!activeProduct?.discount && (
                  <div>
                    <span className={classes.desc}>{t(`${tKey}.discount_fixed`, { discount: activeProduct?.discount })}</span>
                  </div>
                )}
              </div>
            )}
            <div className={classes.content}>
              <Button
                onClick={handleFixedButtonClick}
                className={classNames(classes.button, 'sub-fixed-btn')}
                titleClassName={classes.buttonTitleStyle}
                title={fixedBtnTitle}
                flare={!!fixedButtonConfig?.enabledFlareAnimation}
              />

              {fixedButtonConfig?.badgeTitle && <div className={classes.badge}>
                <span className={classes.badge_title}>
                  <TM k={fixedButtonConfig.badgeTitle} tOptions={activeProduct} />
                </span>
              </div>}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};
