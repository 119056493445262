import { createAsyncThunk } from '@reduxjs/toolkit';

import { checkRedirectResult } from '../api/auth';

import { slice } from './slice'

export const { setUid, setLinkAuthResult } = slice.actions;

export const init = createAsyncThunk(
  `${slice.name}/init`,
  async (_, { dispatch }) => {
    const linkAuthResult = await checkRedirectResult();

    dispatch(setLinkAuthResult(linkAuthResult));

    return linkAuthResult
  }
);