import { type ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AuthErrorCodes } from "firebase/auth";

import { t, TM } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { signInWithApple, signUpWithEmail } from '@web-solutions/base-app/api/auth';

import { Progress } from '@web-solutions/base-app/components/upsales';
import { Title, Text, Preloader } from '@web-solutions/core/ui-elements';
import ErrorPopUp from '@web-solutions/core/containers/error-popup';

import type { ImageSource } from '@web-solutions/core/interfaces/images';

import { selectLinkAuthResult } from '../store/selectors';

import { EmailForm } from '../components/email_form';
import { AppleIdButton } from '../components/AppleIdButton';
import { Divider } from '../components/divider';
import SuccessPopup from '../components/success-popup';

import { useCreateAccountFlow } from '../hooks/use-create-account-flow-selector';
import { useCreateAccount } from '../hooks/use-create-account';

import classes from './style.module.scss';

const tKey = 'create_account';

export type InitialData = {
  email: string,
  password: string,
  confirm_password: string,
}

export enum EventCategory {
  apple = 'firebase_auth_apple',
  email = 'firebase_auth_email',
}
interface Props {
  createAccountFlowConfig: ("email" | "apple_id")[];
  email: string;
  successPopupContent: {
    title: string,
    button_text: string,
    image: ImageSource,
  },
  onNextScreen: () => void;
  title?: string;
  stepsText?: any;
  withInputLabels?: boolean;
  withLetterIcon?: boolean;
  className?: string;
  progressComponent?: ReactNode,
}

const ModuleCreateAccount: React.FC<Props> = ({
  className,
  createAccountFlowConfig,
  title,
  stepsText,
  email,
  progressComponent,
  successPopupContent,
  withInputLabels = true,
  withLetterIcon,
  onNextScreen,
}) => {
  const createAccountFlow = useCreateAccountFlow(createAccountFlowConfig);

  const linkAuthResult = useSelector(selectLinkAuthResult);

  const {
    erorPopup,
    data,
    validation,
    isOpenSuccessPopup,
    handleChange,
    setErorPopup,
    setIsOpenSuccessPopup
  } = useCreateAccount(email, linkAuthResult);

  const [category, setCategory] = useState<EventCategory | ''>('');
  const [isLoading, setIsLoading] = useState(false);

  const emailIndex = createAccountFlow.indexOf('email');
  const appleIdIndex = createAccountFlow.indexOf('apple_id');
  const renderDivider = createAccountFlow.includes('email') && createAccountFlow.includes('apple_id');
  const isEmailFirst = emailIndex < appleIdIndex;
  const isEmailOnly = createAccountFlow.includes('email') && !createAccountFlow.includes('apple_id');

  const handleErrorPopupClose = () => {
    setErorPopup({ show: false, provider: '' });
  };

  const handleSkipClick = () => {
    Analytics.trackEvent('firebase_auth_error_popup', 'try_later');
    setErorPopup({ show: false, provider: '' });
    onNextScreen();
  };

  const handleEmailFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    Analytics.trackEvent(EventCategory.email, EVENT_ACTION.SUBMIT);
    setCategory(EventCategory.email);
    try {
      setIsLoading(true)

      await signUpWithEmail(data?.email, data?.password);

      Analytics.trackEvent('firebase_auth', 'email_success');

      setIsOpenSuccessPopup(true);
    } catch (error: any) {
      Analytics.trackEvent('firebase_auth', 'email_error', { error: error?.message });
      setErorPopup({ show: true, provider: error?.code === AuthErrorCodes.EMAIL_EXISTS ? 'email_used' : 'email' });
    } finally {
      setIsLoading(false)
    }
  }

  const handleAppleClick = () => {
    Analytics.trackEvent(EventCategory.apple, EVENT_ACTION.SUBMIT);
    setCategory(EventCategory.apple);
    signInWithApple()
      .catch(e => {
        Analytics.trackEvent('firebase_auth', 'apple_error', { error: e?.message });
        setErorPopup({ show: true, provider: 'apple' });
      })
  }

  const handleCloseSuccessPopup = () => {
    Analytics.trackEvent('firebase_auth_success_popup', EVENT_ACTION.CLOSE);
    setIsOpenSuccessPopup(false);
    onNextScreen();
  }

  const renderFlow = createAccountFlow.map((step, index) => {
    if (step === 'email') {
      return <EmailForm
        withInputLabels={withInputLabels}
        isEmailOnly={isEmailOnly}
        isLoading={isLoading}
        onSubmit={handleEmailFormSubmit}
        onInputChange={handleChange}
        validation={validation}
        data={data}
        key={index}
        withLetterIcon={withLetterIcon}
      />;
    }
    if (step === 'apple_id') {
      return <AppleIdButton
        text={t(`${tKey}.apple_id_button`)}
        onAppleClick={handleAppleClick}
        key={index}
      />;
    }
    return null;
  });

  if (renderDivider) {
    renderFlow.splice(1, 0, <Divider isEmailFirst={isEmailFirst} />);
  }

  return (
    <>
      {isLoading && <Preloader />}
      <div className={classNames(classes.account_wrap, className)}>
        {progressComponent ? progressComponent : <Progress currentStep='account' stepsText={stepsText} />}
        <Title level='h2' className={classes.title}>
          <TM k={title} defaultKey={`${tKey}.title`} />
        </Title>
        <Text className={classes.subtitle}>{t(`${tKey}.subtitle`)}</Text>
        {renderFlow}
      </div>
      <ErrorPopUp
        visible={erorPopup.show}
        title={t(`${tKey}.error_popup.title`)}
        subTitle={t(`${tKey}.error_popup.${erorPopup.provider}`)}
        btnTitle={t(`${tKey}.error_popup.btn`)}
        skipTitle={t(`${tKey}.error_popup.skip`)}
        onClose={handleErrorPopupClose}
        onSkipClick={handleSkipClick}
      />
      <SuccessPopup
        category={category}
        title={successPopupContent.title}
        button_text={successPopupContent.button_text}
        text={t(`${tKey}.success_popup.${category === EventCategory.email ? 'emailText' : 'appleText'}`)}
        image={successPopupContent.image}
        isOpen={isOpenSuccessPopup}
        onSubmit={handleCloseSuccessPopup}
      />
    </>
  )
}
export default ModuleCreateAccount;