import _debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import type { BaseSyntheticEvent } from 'react';

import Analytics from '@web-solutions/module-analytics';

export type InitialData = {
  email: string,
  password: string,
  confirm_password: string,
}

export const useBaseProcesses = (email: string) => {

  const initialFormData: InitialData = {
    email,
    password: '',
    confirm_password: '',
  }

  const [data, setData] = useState(() => initialFormData);
  const [validation, setValidation] = useState({
    email: true,
    password: true,
    confirm_password: true,
  });

  useEffect(() => {
    setValidation((prevValidation) => ({
      ...prevValidation,
      confirm_password: data.password === data.confirm_password,
    }));
  }, [data.password, data.confirm_password]);

  const debouncedTrackEvent = _debounce((email) => {
    Analytics.trackEvent('firebase_auth', 'edit_email', { email });
  }, 300)


  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;
    let isValid = true;

    const normalizedValue = value.toLowerCase();

    switch (name) {
      case 'email':
        isValid = /^(?!.*\.\.)(?!.*\.$)[^\s@]+@[^\s@]+\.[^\s@]+$/.test(normalizedValue);
        debouncedTrackEvent(normalizedValue);
        break;
      case 'password':
        isValid = normalizedValue.length >= 8 && /\d/.test(normalizedValue) && /[a-zA-Z]/.test(normalizedValue);
        break;
      case 'confirm_password':
        isValid = normalizedValue === data.password;
        break;
      default:
        break;
    }

    setData({ ...data, [name]: value });
    setValidation({ ...validation, [name]: isValid });
  };


  return {
    data,
    validation,
    handleChange,
  }
}