import React from 'react';
import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization';

import { Period } from '@web-solutions/react-billing/constants';

import { T } from '../../../../localization';

import { remoteConfigSelector } from '@web-solutions/core/store/remote-config/selectors';

import { COUNTRY_VAT } from '@web-solutions/core/payment/constants';

import { ProductDetails } from '@web-solutions/core/store/billing/selectors';

//@ts-ignore
import { SUPPORT_EMAIL } from '@web-solutions/core/constants/general';

import { getActualPrices } from '@web-solutions/core/payment/base/product-info/components/simple-view/utilts';

import { Terms } from '@web-solutions/core/payment/components/payment-description/components/terms';
import { ContactUs } from '@web-solutions/core/payment/components/payment-description/components/contact-us';

import classes from './style.module.scss';

interface Props {
  activeProduct: ProductDetails;
  nextChargeDate: string,
  currentPlanPeriod: Period,
}

const tKey = 'manage.payment_description';

const PaymentDescription: React.FC<Props> = ({ activeProduct, nextChargeDate, currentPlanPeriod }) => {
  const countryCode = useSelector((state: any) => state?.billing?.countryCode)

  const { policyLinks, displayVAT, totalPriceType } = useSelector(remoteConfigSelector);

  const { price, descPrice } = getActualPrices({ activeProduct, totalPriceType })

  const displayTaxes = displayVAT && !!COUNTRY_VAT[countryCode];

  const priceString = displayTaxes ? descPrice : price;

  return (
    <div className={classes.root}>
      <div className={classes.main_part}>
        <T
          k={`${tKey}.text`}
          tOptions={{
            plan: t(`core.plans.items_titles.${currentPlanPeriod}`),
            nextChargeDate,
            priceText: priceString,
            period: t(`core.payment_popup.price_texts.${activeProduct.period}`),
          }}
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          components={{ mblink: <a href={policyLinks.moneyBack} /> }}
        />
        <span> </span>
        <ContactUs text={<T k={`${tKey}.contact_us`} />} email={SUPPORT_EMAIL ?? ''} />
      </div>
      <footer>
        <Terms text={<T k={`${tKey}.link`} />} hrefTerms={policyLinks.billingTerms} />
      </footer>
    </div>
  );
};

export default PaymentDescription;
