import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RoutingState {
  isCompleted: boolean;
  currentStep: number;
  startRoute: string | null;
}

const initialState: RoutingState = {
  isCompleted: false,
  currentStep: 0,
  startRoute: null,
};


const slice = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setStartRoute: (state, action: PayloadAction<string>) => {
      state.startRoute = action.payload;
    },
    setCompleted: (state, action: PayloadAction<boolean>) => {
      state.isCompleted = action.payload;
    },
  }
})


export const whitelist = [];

export { slice as routingSlice };


