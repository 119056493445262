import {
  getAuth,
  OAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  linkWithRedirect,
} from "firebase/auth";

import DeviceProps from '@magnus/react-native-device-props';

import { request as requestBillingApi } from '@web-solutions/module-billing/api/network';

//@ts-ignore
import { authFirebase } from 'src/api/auth';

const billingApi = requestBillingApi(process.env.REACT_APP_API_BILLING_URL);

const authDeviceId = (idfm: string) =>
  billingApi.post('/auth/device-id', { device_id: idfm, });

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm => authDeviceId(idfm))

export function signInWithApple(): Promise<void> {
  const provider = new OAuthProvider('apple.com');
  return linkWithRedirect(getAuth().currentUser!, provider)
}

async function linkWithEmailAndPassword(currentUser: any, email: string, password: string): Promise<boolean> {
  const credential = EmailAuthProvider.credential(email, password);
  await linkWithCredential(currentUser, credential);
  return true;
}

export async function signUpWithEmail(email: string, password: string): Promise<boolean> {
  try {
    return await linkWithEmailAndPassword(getAuth().currentUser, email, password);
  } catch (error) {
    const idfm = await DeviceProps.getIDFM();
    await authFirebase(idfm);
    return await linkWithEmailAndPassword(getAuth().currentUser, email, password);
  }
}
