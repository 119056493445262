import _omit from 'lodash/omit';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';

export const removeCreateAccountParam = () => {
  const params = queryString.parse(window.location.search);
  createBrowserHistory()
    .replace(queryString.stringifyUrl({
      url: window.location.pathname,
      query: _omit(params, ['create_account']),
    }));
}