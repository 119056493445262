import classes from '../../style.module.scss';

interface Props {
  text: React.ReactNode,
  hrefTerms: string,
}

export const Terms: React.FC<Props> = ({ text, hrefTerms }) => (
  <span className={classes.terms}>
    <br />
    <a
      href={hrefTerms}
      className={classes.link}
    >
      {text}
    </a>
  </span>
)