import React, { useEffect } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { Title, Text, Modal } from '@web-solutions/core/ui-elements';
import { Button } from '@web-solutions/core/ui-elements';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { ImageSource } from '@web-solutions/core/interfaces/images';

import classes from './style.module.scss';

interface Props {
  text: string | JSX.Element;
  title: string;
  button_text: string;
  isOpen: boolean;
  category: string,
  image?: ImageSource;
  className?: string;
  onSubmit?: () => void;
}

const SuccessPopup: React.FC<Props> = ({
  isOpen,
  title,
  button_text,
  onSubmit,
  text,
  category,
  image,
  className,
}) => {

  useEffect(() => {
    if (isOpen) {
      Analytics.trackEvent(`${category}_success_popup`, EVENT_ACTION.OPEN);
    }
  }, [isOpen, category]);

  return (
    <Modal
      isOpen={isOpen}
      className={classNames(classes.popup, className)}
      overlayClassName={classes.overlay}
    >
      {image && <img className={classes.img} src={image.src} srcSet={image.srcSet} alt='SUCCESS' />}
      <Title level='h2'>{title}</Title>
      <Text className={classes.text}>{text}</Text>
      {onSubmit && <Button
        onClick={onSubmit}
        title={button_text}
      />}
    </Modal>
  )
}


export default SuccessPopup;
