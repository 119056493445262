


import type { LString, BaseOfferProductTypes } from '@web-solutions/core/constants/remote-config';
import type { DefaultUpsaleOfferType, BaseUpsalesRemoteContentParams } from '@web-solutions/core/interfaces/upsaleOffers';

export enum OfferProductId {
  STYLING_GUIDE = 'styling_guide',
  WARDROBE = 'wardrobe',

  //TODO: replace after realizing backend
  NEW_OFFER_1 = 'new_offer_1',
  NEW_OFFER_2 = 'new_offer_2',
  NEW_OFFER_3 = 'new_offer_3'
}

export type OfferProductTypes = BaseOfferProductTypes<OfferProductId>;

export type DefaultUpsaleOfferView = 'WITH_IMAGE' | 'WITH_SLIDER' | 'WITH_IMAGE_AND_TITLE';

export type DefaultUpsaleOffer = DefaultUpsaleOfferType<Omit<OfferProductTypes, 'id'>, DefaultUpsaleOfferView> & BaseUpsalesRemoteContentParams

export type CardsUpsalesOffer = {
  title: LString,
  subtitle: LString,
  submitButton: LString,
  skipButton: LString,
  activeOfferId: OfferProductId | null,
  products: OfferProductTypes[],
}