import _filter from 'lodash/filter';
import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { Plans } from '@web-solutions/core/summary/plans';
import { Button } from '@web-solutions/core/ui-elements';
import Analytics from '@web-solutions/module-analytics';
import type { LString } from '@web-solutions/module-localization';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';
import { PaymentProcessor, PaymentProcessorRef } from '@web-solutions/core/payment';
import { Purchase } from '@web-solutions/core/interfaces/billing';
import { selectOrderDetails, selectPaymentSystem, type ProductDetails, selectOneTimePurchases } from '@web-solutions/core/store/billing/selectors';
import { PaymentSystem } from '@web-solutions/react-billing';
import type { PaymentError } from '@web-solutions/core/interfaces/errors';
import { getProductsTexts } from '@web-solutions/core/utils/products';

import { changePlan, selectProduct, useSliceDispatch, selectSubscription } from '../../slice';

import { Header } from '../header';
import { ReactComponent as CloseIcon } from '../icons/close-icon.svg';
import { tm } from '../../../localization';
import { SuccessModal } from '../success-modal';

import PaymentDescription from './payment-description';

import classes from './style.module.scss';

const tKey = 'manage.plan';
const tKeyPlans = 'core.plans';

interface PlansPageProps {
  category: string,
  withCloseIcon?: boolean,
  title?: LString,
  subtitle?: LString,
  withHeaderContent?: boolean,
  isFirstPurchase?: boolean,
  buttonText?: LString,
  errorPopupButton?: string,
  withBackButton?: boolean,
  successModalEnabled?: boolean,
  onBackClick: () => void,
  onCloseClick?: () => void,
  onSuccessClose?: (purchase?: Purchase) => void,
  onSkipClick?: () => void,
  onPaymentClose?: () => void,
}

export const PlansContent: React.FC<PlansPageProps> = ({
  category,
  withBackButton,
  title,
  subtitle,
  isFirstPurchase,
  withHeaderContent,
  successModalEnabled = true,
  buttonText,
  errorPopupButton,
  withCloseIcon,
  onBackClick,
  onCloseClick,
  onSuccessClose,
  onPaymentClose,
  onSkipClick,
}) => {
  const ref = useRef<PaymentProcessorRef>(null);
  const dispatch = useSliceDispatch();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false)
  const [statusCode, setStatusCode] = useState<string | undefined>();

  const product = useSelector(selectProduct);
  const orderDetails = useSelector(selectOrderDetails);
  const paymentSystem = useSelector(selectPaymentSystem);
  const products = useSelector((state: any) => state.billing.products);
  const oneTimePurchases = useSelector(selectOneTimePurchases)
  const subscription = useSelector(selectSubscription);
  const { showTermsAtPaymentModal } = useRemoteConfig();

  const isChangePlanAvailable = (paymentSystem === PaymentSystem.SOLIDGATE || paymentSystem === PaymentSystem.RECURLY) && product
  const isPurchasedBySolidgate = Boolean(oneTimePurchases.filter(({ payment_service }) => payment_service === PaymentSystem.SOLIDGATE).length)

  const options = useMemo(() => {
    return _filter(products, p => p.id !== product?.id);
  }, [products, product]);

  const [activeProduct, setActiveProduct] = useState(options.find((p) => p.default) || options[0] || {});

  useEffect(() => {
    setActiveProduct(options.find((p) => Object.values(p).includes(activeProduct?.id)) || options.find((p) => p.default) || options[0] || activeProduct);
  }, [activeProduct, options, setActiveProduct]);

  const handleProductClick = (p: ProductDetails) => {
    setActiveProduct(p);
    Analytics.trackEvent(category, EVENT_ACTION.CLICK, { productId: p.id });
  };

  const handleChangeClick = () => {
    Analytics.trackEvent(category, EVENT_ACTION.SUBMIT, { productId: activeProduct.id });
    dispatch(changePlan({ productId: activeProduct.id }))
      .unwrap()
      .then(() => {
        Analytics.trackEvent(category, EVENT_ACTION.SUCCESS, { productId: activeProduct.id });
        handleSuccess();
      })
      .catch((e) => {
        Analytics.trackEvent(category, EVENT_ACTION.ERROR, { productId: activeProduct.id, message: e?.message, code: e?.code });
        setError(true);
        setStatusCode(e?.statusCode);
        throw e;
      });
  };

  const handleSubscribeClick = () => {
    if (isPurchasedBySolidgate) {
      ref.current?.subscribeOnOneClick({ productId: activeProduct.id })
    } else {
      ref.current?.showPaymentPopup()
    }
  }

  const handleCloseErrorClick = () => {
    setError(false);
  }

  const handleSubmitError = () => {
    setError(false)
    ref.current?.showPaymentPopup();
  }

  const handleSuccess = () => {
    if (successModalEnabled) {
      setSuccess(true)
    } else {
      onSuccessClose && onSuccessClose()
    }
  }

  const handleSubscribeOnOneClickError = useCallback((error: PaymentError) => {
    setError(true);
    setStatusCode(error?.code);
  }, [])

  return <div className={classes.wrap}>
    <Header
      title={tm(title, `${tKey}.title`)}
      subtitle={tm(subtitle, `${tKey}.subtitle`)}
      onBackClick={onBackClick}
      withContent={withHeaderContent}
      className={classes.header}
      withBackButton={withBackButton}
    />
    {withCloseIcon && <button className={classes.close} onClick={onCloseClick}>
      <CloseIcon />
    </button>}

    <Plans
      activeProduct={activeProduct}
      products={options}
      showTerms={false}
      onProductClick={handleProductClick}
      productsTexts={getProductsTexts(options, tKeyPlans)}
      className={classes.plansBadge}
    />

    <Button
      type="button"
      className={classes.btn}
      titleClassName={classes.btnTitle}
      title={tm(buttonText, `${tKey}.btn`)}
      onClick={isChangePlanAvailable ? handleChangeClick : handleSubscribeClick}
    />

    {!showTermsAtPaymentModal &&
      <div className={classes.description}>
        <PaymentDescription
          activeProduct={activeProduct}
          nextChargeDate={dayjs(subscription?.next_charge_at).format('LL')}
          currentPlanPeriod={product?.period!}
        />
      </div>
    }

    <SuccessModal
      category={`${category}_success_modal`}
      isOpen={success}
      tKey={isFirstPurchase ? `${tKey}.first_purchase_success_modal` : `${tKey}.success_modal`}
      onClose={onSuccessClose}
    />

    <PaymentProcessor
      ref={ref}
      orderDetails={orderDetails}
      activeProduct={activeProduct}
      onSuccess={handleSuccess}
      onClose={onPaymentClose}
      onSubscribeOnOneClickError={handleSubscribeOnOneClickError}
    />

    <ErrorPopup
      visible={!!error}
      onSubmit={handleSubmitError}
      statusCode={statusCode}
      buttonTitle={errorPopupButton}
      onClose={handleCloseErrorClick}
      onSkipClick={onSkipClick}
    />
  </div>
}