export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const PREFIX_WITH_PAYMENT = '-pp';

export const EVENT_ACTION = {
  OPEN: 'open',
  SUCCESS: 'success',
  ERROR: 'error',
  CLOSE: 'close',
  SKIP: 'skip',
  CLICK: 'click',
  SUBMIT: 'submit',
  CANCEL: 'cancel',
  DELETE: 'delete',
  SELECTED: 'selected',
  BACK: 'back',
  MOUNT: 'mount',
  SENT: 'sent'
};
