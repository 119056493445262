import classes from '../../style.module.scss';

interface Props {
  text: React.ReactNode,
  email: string,
}

export const ContactUs: React.FC<Props> = ({ text, email }) => (
  <>
    {text}
    <a
      href={`mailto:${email}?subject=${encodeURIComponent('Payment Description')}`}
      className={classes.link}
    >
      {` ${email}`}.
    </a>
  </>
)