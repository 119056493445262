import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import classes from './style.module.scss';

const tKey = 'create_account';

export const Divider: React.FC<{ isEmailFirst: boolean }> = ({ isEmailFirst }) => {
  const text = isEmailFirst ? t(`${tKey}.or_apple_id`) : t(`${tKey}.or_email`);
  return (
    <div className={classNames(classes.divider, { [classes.for_email]: isEmailFirst })}>{text}</div>
  );
};