import {
  OAuthProvider,
  getAuth,
  confirmPasswordReset,
  sendPasswordResetEmail,
  checkActionCode,
  applyActionCode,
  signInWithEmailAndPassword,
  getRedirectResult
} from 'firebase/auth';

import request from '@web-solutions/core/utils/network';

export const confirmPassword = async (oobCode: string, newPassword: string) => {
  await confirmPasswordReset(getAuth(), oobCode, newPassword)
}

export const signInUser = async (email: string, password: string) => {
  try {
    const r = await signInWithEmailAndPassword(getAuth(), email, password);
    return r?.user?.uid;
  } catch (error) {
    console.error("ERROR", error);
  }
};

export const reSendPasswordReset = async (email: string, platform: string) => {
  await request.post('/auth/reset-password', { email, platform });
}

export const checkIsCodeValid = async (oobCode: string) => {
  const auth = getAuth();
  return checkActionCode(auth, oobCode).then()
}

export const handleRecoverEmail = (oobCode: string) => {
  const auth = getAuth();
  let restoredEmail: string | null | undefined = null;

  checkActionCode(auth, oobCode)
    .then((info) => {
      restoredEmail = info['data']['email'];
      return applyActionCode(auth, oobCode);
    })
    .then(() => {
      if (restoredEmail) {
        sendPasswordResetEmail(auth, restoredEmail)
          .catch((error) => {
            console.error('ERROR', error);
          });
      }
    })
    .catch((error) => {
      console.error('ERROR', error);
    });
}

export const checkRedirectResult = () => {
  return getRedirectResult(getAuth())
    .then((result) => {
      if (result) {
        const credential = OAuthProvider.credentialFromResult(result)
        if (credential) {
          return result.user;
        }
      }
    })
    .catch((error) => {
      console.warn(error);
      return error;
    });
}