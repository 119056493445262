
import { useBaseProcesses } from './use-base-processes';

export const useReAuthentication = () => {
  const {
    data,
    validation,
    handleChange,
  } = useBaseProcesses('');

  return {
    data,
    validation,
    handleChange: handleChange
  };
};
